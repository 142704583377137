import cn from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { EnumRoutePaths } from 'src/configs/routes';
import { convertAllTagsArr, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { MediaManager } from 'src/manager/MediaManager';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setGameBoxWidgetOpen, setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { setSelectedGame, setSelectedProvider, setSelectedTag } from 'src/store/games/actions';
import { toggleMobileMenu } from 'src/store/mobile/actions';
import { NavigationItem } from 'src/types/res-dto/categories-res';
import { RootState } from 'src/types/store-types';
import { MOBILE_MENU_PROMOTIONS } from 'src/utils/constants';
import Language from '../../Language';
import { Logo } from '../../Logo';
import StaticLists from './StaticLists';
import _styles from './styles.module.scss';

const MobileMenu: FC = () => {
  const { t }: Translation = useTranslation();
  const navigate = useNavigate();
  const categoryMenuRef: any = useRef();
  const { locale } = useI18n();

  const _toggleMobileMenu = useConnectedAction(toggleMobileMenu);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);
  const _selectedGame = useConnectedAction(setSelectedGame);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _setGameBoxWidgetOpen = useConnectedAction(setGameBoxWidgetOpen);

  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { freeSpinGames } = useSelector((state: RootState) => state.integrations);
  const { user } = useSelector((state: RootState) => state.user);
  const { selectedTag } = useSelector((state: RootState) => state.games);
  const { generalConfigs, categoriesData, navigation, tagsMetaInfo } = useSelector((state: RootState) => state.configs);
  const url = localStorage.getItem('url') as string;
  const [selectMenuItem, setSelectedMenuItem] = useState<string>('');

  setBodyOverflow('unset');

  const selectTag = (tag: string): void => {
    window.location.href = `${url}/category/${selectMenuItem}`;
    _setSelectedTag(tag);
    _setSelectedProvider(['all_providers']);
    //  navigate(`/category/${selectMenuItem}`);
    closeCategoriesMenu();
  };
  const homeTag = useMemo(() => {
    return navigation.filter((f) => f.name === selectedCategory)[0];
  }, [selectedCategory, navigation]);

  useEffect(() => {
    if (!!categoriesData && !!selectedCategory && !!homeTag && locale) {
      convertAllTagsArr(categoriesData, selectedCategory, homeTag, tagsMetaInfo, locale);
    }
  }, [categoriesData, selectedCategory, homeTag, tagsMetaInfo, locale]);

  const selectGame = (game: any): void => {
    const _game = navigation?.find((f) => f.name === game);

    _selectedGame({ id: _game?.gameId });
    closeCategoriesMenu();
  };

  const selectReferAFriend = (): void => {
    if (user) {
      _openAccountModal({ section: 'referAFriend', subSection: 'referAFriend', from: 'menu' });
      closeCategoriesMenu();
    } else {
      _openAuthModal('login');
    }
  };

  const selectPromo = (promoObj: { promo: string; needLogIn?: boolean; promo_id?: number }): void => {
    const _user = localStorage.getItem('user');

    if (promoObj?.needLogIn && !_user) {
      window.location.href = url;
      _openAuthModal('login');
    } else {
      if (promoObj.promo === 'gamebox') {
        _setGameBoxWidgetOpen();
      } else if (promoObj.promo === 'super_arena') {
        window.location.href = `${url}/promotions?promo=${promoObj.promo_id}`;
        //  navigate(`/promotions?promo=${promoObj.promo_id}`);
        closeCategoriesMenu();
      } else if (promoObj.promo === 'promotions') {
        closeCategoriesMenu();
        _setSelectedCategory(promoObj.promo);
        navigate('/promotions');
      } else if (promoObj.promo === 'ref-friend') {
        changeSelectedMenuItem('referAFriend', 'refer');
      } else {
        selectCategory(promoObj.promo);
      }
    }
  };

  const selectPromotion = (): void => {
    window.location.href = `${url}${EnumRoutePaths.PROMOTIONS}`;
    closeCategoriesMenu();
    //navigate(EnumRoutePaths.PROMOTIONS);
  };

  const selectCategory = (menuItem: string, type = 'category'): void => {
    window.location.href = `${url}/${type}/${menuItem}`;
    closeCategoriesMenu();
    _setSelectedProvider(['all_providers']);
    // navigate(`/${type}/${menuItem}`);

    _setSelectedCategory(menuItem);
    _setSelectedTag('');
  };

  const transformCategoryName = (menuItem: string): string => {
    let category;

    if (menuItem === 'betting') {
      category = 'sport';
    } else if (menuItem === 'tournament') {
      category = 'casinoTournament';
    } else {
      category = menuItem;
    }
    return category;
  };

  const checkSubType = (_navItemData: NavigationItem | undefined, menuItem: string): void => {
    if (_navItemData && _navItemData?.subType === 'staticPage') {
      selectCategory(menuItem);
    } else {
      !selectedTag && _setSelectedTag(categoriesData[menuItem]?.tags?.[0].tagName);
      _setSelectedCategory(menuItem);
    }
  };

  const actionByNavigationType = (type: string, menuItem: string, _navItemData: NavigationItem): void => {
    const category = transformCategoryName(menuItem);
    localStorage.setItem('selectedProvider', 'all_providers');
    switch (type) {
      case 'category':
      case 'sport':
      case 'fun-mission':
        if (
          categoriesData[menuItem]?.tags?.length < 2 ||
          ['silvaSeries', 'dinhutech', 'crashGames', 'virtualSport', 'tigerFortune', 'fun-mission'].includes(
            menuItem
          ) ||
          (categoriesData[menuItem]?.tags?.length > 2 && !hasChildren(menuItem))
        ) {
          selectCategory(menuItem);
        } else {
          checkSubType(_navItemData, menuItem);
        }

        setSelectedMenuItem(category === selectMenuItem ? '' : category);
        break;
      case 'content':
        selectCategory(menuItem, 'content');
        break;
      case 'game':
        selectGame(menuItem);
        break;
      case 'raceIframe':
      case 'tombala':
      case 'blogIframe':
      case 'casinoTournament':
      case 'cybersport':
        selectCategory(menuItem);
        setSelectedMenuItem(category === selectMenuItem ? '' : category);
        break;
      case 'refer':
        selectReferAFriend();
        break;
      case 'promo':
      case 'static':
        selectPromotion();
        setSelectedMenuItem(category === selectMenuItem ? '' : category);
        _setSelectedCategory(menuItem);
        break;
      case 'externalLink':
        window.open(_navItemData.redirectionUrl, '_blank');
        break;
      default:
        setSelectedMenuItem(category === selectMenuItem ? '' : category);
    }
  };

  const changeSelectedMenuItem = (menuItem: string, type = ''): void => {
    const _user = localStorage.getItem('user');

    if (menuItem !== 'language') {
      const _navItemData = navigation.find((f) => f.name === menuItem) as NavigationItem;

      if (_navItemData?.needLogIn && !_user) {
        window.location.href = `${url}`;
        localStorage.setItem('menuItem', menuItem);
        _openAuthModal('login');
      } else {
        actionByNavigationType(type, menuItem, _navItemData);
      }
    }
  };

  const hasChildren = (category: string): boolean => {
    return Boolean(
      categoriesData[category]?.tags?.length > 1 && navigation?.find((f: any) => f.name === category)?.hasHomeTag
    );
  };

  const closeCategoriesMenu = (): void => {
    setBodyOverflow('set');
    setSelectedMenuItem('');
    _toggleMobileMenu();
  };

  const categoriesList = useMemo(() => {
    const _ = navigation
      .filter((n) => n.showInNavigation)
      .filter((n) => n.name !== 'liveSport')
      ?.map((m) => {
        if (m.name === 'sport') {
          m.name = 'betting';
        }
        return m;
      });

    let withoutPromotions = null;

    if (generalConfigs?.hasMobileMenuPromotions) {
      const _promoNames = MOBILE_MENU_PROMOTIONS.map((m) => m.promo);
      withoutPromotions = _.filter((n) => !_promoNames.includes(n.name));
    }

    return withoutPromotions ?? _;
  }, [navigation, generalConfigs]);

  const renderTags = (category: { name: string }): JSX.Element => {
    return (
      <>
        {categoriesData[category.name]?.tags?.map((tag, j) => {
          const tagName = tag?.tagTrName ? tag?.tagTrName : tag.tagName;
          const isSelected = selectedTag === tagName;
          const tagStyle = {
            color: isSelected ? 'var(--text-brand)' : 'var(--text-primary)',
          };

          return (
            <li key={j + tagName} style={tagStyle} onClick={() => selectTag(tagName)}>
              {tag?.tagTrName ? tag?.tagTrName : t(tag.tagName)}
            </li>
          );
        })}
      </>
    );
  };

  const renderCategory = useCallback(
    (category: any) => {
      return (
        <React.Fragment key={category.name}>
          <Button
            className={cn(_styles.accordion, 'mobile_menu_accordion')}
            onClick={() => changeSelectedMenuItem(category.name, category.type)}
            justifyContentValue="space-between"
          >
            <div className={_styles.inner_accordion}>
              <div>
                {category?.imageSource?.includes('.svg') ? (
                  <SvgIcon
                    src={MediaManager.getSrcFromMediaAssets(category?.imageSource, '/categoryIcons/')}
                    className={cn(_styles.category_icon, 'mobile_menu_category_icon')}
                  />
                ) : (
                  <img
                    src={MediaManager.getSrcFromMediaAssets(category?.imageSource, '/categoryIcons/')}
                    alt={category.name}
                  />
                )}
                {t(`category_${category.name}${category.type === 'game' ? 'game' : ''}`)}
                {category?.label && (
                  <img
                    src={MediaManager.getSrcFromLocaleFolder(category?.label, '/labels/')}
                    className={_styles.label}
                  />
                )}
              </div>
              {hasChildren(category.name) && (
                <SvgIcon
                  src={icons.arrowDown}
                  className={cn(_styles.arrow, { [_styles.rotateArrow]: selectMenuItem === category.name })}
                />
              )}
            </div>
          </Button>
          {selectMenuItem === category.name && hasChildren(category.name) && (
            <ul className={_styles.panel}>
              {freeSpinGames && Object.values(freeSpinGames).length > 0 && selectedCategory === 'casino' && (
                <li
                  style={{ color: selectedTag === 'free_rounds' ? 'var(--text-brand)' : 'var(--text-primary)' }}
                  onClick={() => selectTag('free_rounds')}
                >
                  {t('free_rounds')}
                </li>
              )}
              {renderTags(category)}
            </ul>
          )}
        </React.Fragment>
      );
    },
    [selectMenuItem, categoriesData]
  );

  const hasPromotion = useMemo(() => {
    return !!navigation.find((f) => f.name === 'promotions' && f?.subType !== 'staticPage');
  }, []);

  return (
    <div
      className={cn(_styles.mobile_menu_container, { [_styles.mobile_menu_vertical]: generalConfigs.isVertical })}
      ref={categoryMenuRef}
    >
      <div>
        <div className={_styles.logo}>
          <Logo settings={{ src: 'logo.png' }} />
        </div>
        <div className={_styles.menu_icon_wrapper}>
          <p className={_styles.title}>{t('menu')}</p>
          <Button className={_styles.close_menu_wrapper} onClick={closeCategoriesMenu} icon={icons.close} />
        </div>
      </div>
      <div className={_styles.menu_content_wrapper}>
        <div className={_styles.promotions_section}>
          {generalConfigs?.hasMobileMenuPromotions &&
            (generalConfigs?.promotions ?? MOBILE_MENU_PROMOTIONS)?.map(
              (m: { promo: string; imgSrc?: string; styles?: Record<string, string> }) => (
                <Button
                  key={m?.promo}
                  className={_styles.promotions_section_btn}
                  type="button"
                  onClick={() => selectPromo(m)}
                  style={m?.styles}
                >
                  <img
                    src={m?.imgSrc ? `/images/mobile/${m?.imgSrc}` : `/images/mobile/${m?.promo}_promo.svg`}
                    alt={m?.imgSrc ? m?.imgSrc : `${m?.promo}_promo.svg`}
                  />
                </Button>
              )
            )}
        </div>
        {generalConfigs?.hasReferAFriend && (
          <div className={_styles.refer_a_friend_section}>
            <Button
              fontWeight="bold"
              variant="contained"
              color="transparent"
              onClick={() => changeSelectedMenuItem('referAFriend', 'refer')}
              className={_styles.referAFriend}
            >
              {t('referAFriendInvite')}
            </Button>
          </div>
        )}
        {categoriesList.map((category: any) => renderCategory(category))}
        {hasPromotion && !generalConfigs.isVertical && (
          <Button
            className={cn(_styles.accordion, 'mobile_menu_accordion')}
            onClick={() => changeSelectedMenuItem('promotions', 'promo')}
            justifyContentValue="space-between"
          >
            <div className={_styles.promotions}>
              <span className="icon-promos mobile_menu_promo_icon" />
              {t('button_promos')}
            </div>
          </Button>
        )}
        <div className={_styles.underLiner} />
        {!!generalConfigs?.languages?.length && (
          <Button
            className={cn(_styles.accordion, _styles['lg-item'], 'mobile_menu_accordion')}
            onClick={() => changeSelectedMenuItem('language')}
          >
            <div
              className={cn(_styles.menu_content_text, {
                [_styles.lngBar]: generalConfigs.isVertical,
              })}
            >
              <div>
                <span className="icon-language" />
                {t('language')}
              </div>
              <Language />
            </div>
          </Button>
        )}
        <StaticLists
          changeSelectedMenuItem={changeSelectedMenuItem}
          selectMenuItem={selectMenuItem}
          openInfoAndRules={_openAccountModal}
        />
      </div>
    </div>
  );
};
export default MobileMenu;
